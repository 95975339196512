<style>
	/* .select2-container .select2-selection--single {
		height: 23px;
	}
	.select2-container .select2-selection--single .select2-selection__rendered {
		padding: 0;
	}
	.select2-results__option[aria-selected]:hover {
	    background: #4a97f7;
	    color: #fff;
	} */
</style>
<template>
	<div>
		<div class="row">
			<div class="col-lg-12">
				<div class="panel">
				    <div class="panel-body">
				        <h3 class="panel-title"><img :src="require('@/assets/images/icons/transactions.svg')" class="panel-icon" alt=""> Giao dịch</h3>
				        <form action="#" @submit.prevent="transactions">
				        	<div class="form-group">
				        		<label>Tài khoản :</label>
				        		<input type="text" class="form-control" v-model="form.username" placeholder="Chọn hoặc nhập tài khoản..." list="listUsers">
				        		<datalist id="listUsers">
				        			<option :value="x.id" v-for="x in users">{{ x.text }}</option>
				        		</datalist>
				        		<!-- <Select2 class="form-control" v-model="form.username" :options="users" :settings="select2Settings"></Select2> -->
				        	</div>
				        	<div class="form-group">
						        <label>Số tiền :</label>
						        <vue-numeric class="form-control" v-model="form.money" v-bind:minus="false" separator="," placeholder="Nhập số tiền" required=""></vue-numeric>
						    </div>
						    <div class="form-group">
						    	<label>Khuyến mãi ( % ) :</label>
						    	<input type="number" class="form-control" v-model="form.bonus" placeholder="Nhập số phần trăm khuyến mãi">
						    </div>
						    <div class="form-group">
						        <label>Thao tác :</label>
						        <select class="form-control" v-model="form.action">
						        	<option value="minus">Trừ tiền</option>
						        	<option value="plus">Cộng tiền</option>
						        </select>
						    </div>
						    <div class="form-group">
						    	<label>Chọn nhanh nội dung :</label>
						    	<select class="form-control" v-model="selectFastContent">
						    		<option :value="fc" v-for="fc in fastContent">{{ fc }}</option>
						    	</select>
						    </div>
						    <div class="form-group">
						    	<label>Nội dung :</label>
						    	<textarea class="form-control" v-model="content" rows="2" placeholder="Nội dung cộng tiền ( VD : Nạp tiền qua Momo, Vietcombank, ZaloPay, ViettelPay )"></textarea>
						    </div>
						    <div class="alert alert-info b-info" ref="content">
					        	<b>{{ actionText | ucfirst }}</b> vào tài khoản <b>{{ form.username }}</b>  với số tiền là <b>{{ form.bonus > 0 ? form.money + form.money * form.bonus / 100 : form.money | numberFormat }} VND {{ form.bonus > 0 ? '( Khuyến mãi '+ form.bonus +'% )' : '' }}</b>
					        </div>
				        	<button class="btn b-success btn-block">Thực hiện {{ actionText }}</button>
				        </form>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped=""></style>

<script>

	import VueNumeric from 'vue-numeric'
	/*import Select2 from 'v-select2-component'*/

	export default {
		components: {
			VueNumeric,
			// Select2
		},
		created () {
			this.getUsers();
		},
		props: ['settings', 'site'],
		data () {
			return {
				selectFastContent: 'Admin',
				fastContent: this.site.fastContents,
				form: {
					action: 'plus',
					money: 0,
					bonus: 0,
					content: ''
				},
				select2Settings: {
					placeholder: 'Chọn tài khoản',
					theme: "bootstrap",
					width: '100%'
				},
				users: []
			}
		},
		computed: {
			actionText () {
				if (this.form.action == 'minus') {
					this.form.bonus = 0;
					this.selectFastContent = 'Admin';
				}
				return this.form.action == 'plus' ? 'cộng tiền' : 'trừ tiền';
			},
			content () {
				if (this.form.action == 'minus') {
					this.form.bonus = 0;
					this.selectFastContent = 'Admin';
				}
				let content = this.selectFastContent;
				if (this.selectFastContent.includes('Admin')) {
					content = content + ' ' + this.actionText + ' vào tài khoản';
				}
				if (this.form.action == 'plus') {
					if (this.form.bonus > 0) {
						content = content + ' ( Khuyến mãi '+ this.form.bonus +'% )';
					}
				}
				this.form.content = content;
				return content;
			}
		},
		methods: {
			transactions () {
				if (!this.form.username) {
					return this.$swal('Thông báo', 'Bạn chưa chọn tài khoản cần thao tác', 'error');
				}
				if (!this.form.content) {
					this.form.content = this.content;	
				}
				this.$swal({
					title: 'Xác nhận ?',
					html: this.$refs.content.innerHTML,
					imageUrl: require('@/assets/images/icons/transactions.svg'),
					imageHeight: '100px',
					showCancelButton: 1,
					confirmButtonText: 'Đồng ý'
				}).then((isConfirm) => {
					if (isConfirm.value) {
						this.$http.post('admin/transactions', this.form).then(res => {
							if (res.body.status === 1) {
								this.$swal('Thông báo', this.$refs.content.innerHTML, 'success');
							} else {
								this.$swal('Thông báo', res.body.message, 'error');
							}
						});
					}
				});
			},
			getUsers () {
                this.$http.post('admin/users', {action: 'lists'}).then(res => {
                	if (res.body) {
                		let i = res.body.length;
                		res.body.filter(x => {
	                   		this.users.push({
	                   			id: x.username,
	                   			text: i-- + '. ' + x.username + ' - Số dư : ' + this.$options.filters.numberFormat(x.money) + ' ' + this.settings.currency + ' ( ' + x.role + ' )'
	                   		});
	                   	});
                	}
                });
            }
		}
	}
</script>